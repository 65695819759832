import type { S3BucketEndpoints } from "$lib/aws/s3/s3_utils";
import type { NormalizedPictureData, PictureData } from "$lib/server/entities/picture/BasePictureEntity";

export function generate_profile_header_image_url(profile_picture_pathname: string, s3_endpoints: S3BucketEndpoints): string {

    let s3_endpoint: string = s3_endpoints.systempictures;
    let to_return = `${s3_endpoint}/${profile_picture_pathname}`;

    return to_return;
}

export function normalize_picture_datas(picture_datas: PictureData[]): NormalizedPictureData[] {

    let normalized_picture_datas: {[uuid: string]: NormalizedPictureData} = {};
    for (let picture of picture_datas) {

        let path_components = picture.path_name.split("/");
        let filename = path_components.pop();
        let picture_type = filename!.split(".")[0];
        let uuid = path_components.pop()!;
        
        // console.log("------------------------>", picture.path_name, path_components, filename, picture_type, uuid);

        let data = normalized_picture_datas[uuid];
        if (data === undefined) {
            normalized_picture_datas[uuid] = {uuid};
            data = normalized_picture_datas[uuid];
        }

        if (picture_type === 'full') {
            data.full = picture;
        } else {
            data.thumbnail = picture;
        }
    }

    return Object.values(normalized_picture_datas);
}